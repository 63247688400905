import React from "react";
import "./CachacaVermelhaMobile.css";
import logo from "../../../assets/cachaca-vermelha/logo-cachaca.png";
import produto from "../../../assets/cachaca-vermelha/produto.png";

export default function Cachaca() {
  return (
    <div className="container-cachaca-vermelha-mobile">
      <div className="content-group-wrapper-cachaca-vermelha-mobile">
        <div className="content-group-container-cachaca-vermelha-mobile">
          <div className="logo-img-container-cachaca-vermelha-mobile">
            <img src={logo} className="logo-img-cachaca-vermelha-mobile"></img>
          </div>
        </div>
        <div className="content-group2-container-cachaca-vermelha-mobile">
        <div className="about-cachaca-vermelha-mobile">
         <div className="about-title-cointainer-cachaca-vermelha-mobile"> 
        <p className="about-title-cachaca-vermelha-mobile">TRADICIONAL BLEND EXCLUSIVO</p>
        </div>
        <div className="about-text-container-cachaca-vermelha-mobile"> 
        <p className="about-text2-cachaca-vermelha-mobile">Destilada artesanalmente usando nossa antiga receita,<br/> a Cachaça Tradicional Poços de Caldas entrega um<br/>  sabor aveludado, suave e equilibrado, seu aroma é <br/> refinado, com sutis traços de madeira.</p> 
        <p className="about-text2-cachaca-vermelha-mobile">- Produzida 100% na região <text className="about-text-cachaca-vermelha-mobile">mineira brasileira.</text></p>
        <p className="about-text2-cachaca-vermelha-mobile">- A escolha certa do terreno, uma boa preparação do solo,<br/> uma coleta de cana selecionada, e um processo de alta<br/> qualidade, dão as características exclusivas de nosso<br/> produto.</p>
        <p className="about-text-cachaca-vermelha-mobile">- Aroma refinado e adocicado <text className="about-text2-cachaca-vermelha-mobile">que remete a madeira.</text></p>
        <p className="about-text2-cachaca-vermelha-mobile">- Sabor rico, equilibrado, <text className="about-text-cachaca-vermelha-mobile">suave e aveludado,</text><br/> com notas aromáticas de frutas secas.</p>
        </div> 
        <div className="line-container-cachaca-vermelha-mobile"></div>
        <p className="about-text3-cachaca-vermelha-mobile">750 ml | ALC 40% Vol.</p>  
        <div className="line-container-cachaca-vermelha-mobile"></div>    
        </div>

        </div>
        <div className="content-group3-container-cachaca-vermelha-mobile">
        <p className="title-cachaca-vermelha-mobile">Presenteie <br/>Certo!</p>
        <div className="produto-nutricional-img-container-cachaca-vermelha-mobile">
          
            <img src={produto} className="produto-nutricional-img-cachaca-vermelha-mobile"/>
        </div>
        
        </div>
      </div>
    </div>
  );
}
