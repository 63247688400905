import React, { useEffect, useRef, useState } from "react";
import "./Vinho.css"
import leftTop from "../../../assets/Vinho/left-top-vinho.png"
import rightTop from "../../../assets/Vinho/right-top-vinho.png"
import leftBottom from "../../../assets/Vinho/left-bottom-vinho.png"
import rightBottom from "../../../assets/Vinho/right-bottom-vinho.png"
import titleVinho from "../../../assets/Vinho/title-vinho.png"
import produtoVinho from "../../../assets/Vinho/produto-vinho-animacao.gif"
import logoVinho from "../../../assets/Vinho/logo-vinho.png"
import logo2Vinho from "../../../assets/Vinho/logo2-vinho.png"
//@ts-ignore
import soundVinho from "../../../assets/Vinho/0003075.mp3"
import { useAudio } from "../../../contexts/Audio";
export default function Vinho(props: any) {
    const {tocar, setTocar} = useAudio();
    const audio = new Audio(soundVinho);
    
    useEffect(() => {
        if(tocar) { 
       //@ts-ignore
        document.getElementById("div1").addEventListener("mouseover", () => {
          audio.play();
          console.log(tocar)
          setTocar(false);
        });
        
        
    }
      }, []);

    

    return(
    <div className="container-vinho"  >  
    <img src={leftTop} className="left-top-vinho"/>  
    <img src={rightTop}   className="right-top-vinho"/>
    <img src={leftBottom} className="left-bottom-vinho"/>  
    <img src={logoVinho} className="logo-vinho"/>  
    <img src={rightBottom} className="right-bottom-vinho"/>
    <img src={logo2Vinho}  className="logo2-vinho"/> 

    <div className="content-group-vinho">
    <div className="topbar-vinho">
    <img src={titleVinho} className="title-img-vinho"/>    
    </div> 
    <div className="content-vinho">
     <img src={produtoVinho} className="produto-vinho" id="div1"/>   
    </div>
    </div>     
    </div>    

    );
}   

