import * as React from "react";
import { createContext, ReactNode, useContext, useState } from "react";

interface AudioContextTypes  {
    tocar: boolean;
    setTocar: (data: boolean) => void;
}


const AudioContextDefaultValues: AudioContextTypes = {
    setTocar: () => {},
    tocar: true,
   
}

const AudioContext = createContext<AudioContextTypes>(
    AudioContextDefaultValues
);

export function useAudio() {
    return useContext(AudioContext);
}

type Props = {
    children: ReactNode;
};


export function AudioProvider({ children }: Props) {
    const [tocar, setTocarConst] = useState<boolean>(true);


    const setTocar = (data: boolean) => {
        console.log('data', data, tocar);
      setTocarConst(data);
      console.log('datya', data, tocar);
    };

  
    const value = {
        setTocar,
        tocar
    };
  
    return (
      <>
        <AudioContext.Provider value={value}>
          {children}
        </AudioContext.Provider>
      </>
    );
  }