import "./DoceDeLeite.css";
import logo from "../../../assets/doce-de-leite/logo-doce-de-leite.png";
import produto from "../../../assets/doce-de-leite/produto-doce-de-leite.png";
export default function DoceDeLeite() {
return(
    <div className="container-doce-de-leite">
    <div className="title-content-doce-de-leite">
        <div className="title-logo-doce-de-leite">
        <p className="title-doce-de-leite">Doce de Leite <br/> Tradicional</p>
        <img src={logo} className="logo-doce-de-leite" />
        </div>
        <p className="subtitle-doce-de-leite">Receita familiar de tradição mineira!</p>
    </div> 

    <div className="about-doce-de-leite">
         <div className="about-title-cointainer-doce-de-leite"> 
        <p className="about-title-doce-de-leite">O Doce de Leite <br/> Poços de Caldas</p>
        </div>
        <div className="about-text-container-doce-de-leite"> 
        <p className="about-text-doce-de-leite">é um produto superior<br/>aos similares no mercado, produzido de uma maneira diferenciada, para atingir<br/>um alto padrão de sabor e <br/>agradar aos paladares<br/>mais refinados. </p> 
        </div> 
        <div className="line-container-doce-de-leite"></div>
        <p className="about-text3-doce-de-leite">Desde 1949</p>  
        <div className="line-container-doce-de-leite"></div>    
        </div> 
        <img src={produto}  className="produto-doce-de-leite"/>  

    </div>
)
}