import React from "react";
import logoContraCapa from "../../../assets/contra-capa/logo-contra-capa.png"
import "./ContraCapa.css"
export default function ContraCapa() {
    return (
    <div className="container-contra-capa">    
    <img src={logoContraCapa} className="img-contra-capa"/>   
    <div className="line-container-contra-capa"></div>
    <div className="text-bold-container-contra-capa">
    <p className="text-bold-contra-capa">INDÚSTRIA E COMÉRCIO DE DOCES POÇOS DE CALDAS LTDA.</p>    
    </div>
    <div className="text-container-contra-capa">
    <p className="text-contra-capa">POÇOS DE CALDAS | MG | CEP 37704-140 - CNPJ 22.091.904/0001-02</p>
    <p className="text-contra-capa">Endereço para correspondência: Rua Paraíba, 349 | Sala 107 | Centro | CEP 37701-022</p> 
    <p className="text-contra-capa">Celular: (35) 99977-5020 | Telefone: (35) 3722-5020</p> 
    <p className="text-contra-capa">pocosdecaldas.alimentos@pocos-net.com.br</p>       
    </div>
    </div>    

    );
}