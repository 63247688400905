import React, { useEffect, useState } from "react";
import "./VinhoMobile.css"
import leftTop from "../../../assets/Vinho/left-top-vinho.png"
import rightTop from "../../../assets/Vinho/right-top-vinho.png"
import leftBottom from "../../../assets/Vinho/left-bottom-vinho.png"
import rightBottom from "../../../assets/Vinho/right-bottom-vinho.png"
import titleVinho from "../../../assets/Vinho/title-vinho.png"
import produtoVinho from "../../../assets/Vinho/produto-vinho-animacao.gif"
import logoVinho from "../../../assets/Vinho/logo-vinho.png"
import logo2Vinho from "../../../assets/Vinho/logo2-vinho.png"
import Sound, { ReactSoundProps } from 'react-sound';
import { useAudio } from "../../../contexts/Audio";
//@ts-ignore
import soundVinho from "../../../assets/Vinho/0003075.mp3"
export default function Vinho() {
    // const {tocar, setTocar} = useAudio();
    // const audio = new Audio(soundVinho);
    
    // useEffect(() => {
    //     if(tocar) { 
    //    //@ts-ignore
    //     document.getElementById("div2").addEventListener("focus", () => {
    //       audio.play();
    //       console.log(tocar)
    //       setTocar(false);
    //     });
        
        
    // }
    //   }, []);
    return(
    <div className="container-vinho-mobile">
    <img src={leftTop} className="left-top-vinho-mobile"/>  
    <img src={rightTop}   className="right-top-vinho-mobile"/>
    <img src={leftBottom} className="left-bottom-vinho-mobile"/>  
    <img src={logoVinho} className="logo-vinho-mobile"/>  
    <img src={rightBottom} className="right-bottom-vinho-mobile"/>
    <img src={logo2Vinho}  className="logo2-vinho-mobile"/>
      
    <div className="content-group-vinho-mobile">
    <div className="topbar-vinho-mobile">
    <img src={titleVinho} className="title-img-vinho-mobile"/>    
    </div> 
    <div className="content-vinho-mobile">
    <img src={produtoVinho} className="produto-vinho-mobile"/>   
      
    </div>
    </div>     
    </div>    

    );
}   