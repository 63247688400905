import React from "react";
import "./Cachaca.css";
import logo from "../../../assets/cachaca/logo-cachaca.png";
import produto from "../../../assets/cachaca/produto.png";

export default function Cachaca() {
  return (
    <div className="container-cachaca">
      <div className="content-group-wrapper-cachaca">
        <div className="content-group-container-cachaca">

          <div className="title-group-container-cachaca">
            <div className="title-container-cachaca">
              <p className="title-cachaca">Presenteie <br/>Certo!</p>
            </div>
          </div>
          <div className="logo-img-container-cachaca">
            <img src={logo} className="logo-img-cachaca"></img>
          </div>
        </div>
        <div className="content-group2-container-cachaca">
        <div className="about-cachaca">
         <div className="about-title-cointainer-cachaca"> 
        <p className="about-title-cachaca">TRADICIONAL BLEND EXCLUSIVO</p>
        </div>
        <div className="about-text-container-cachaca"> 
        <p className="about-text2-cachaca">Destilada artesanalmente usando nossa antiga receita,<br/> a Cachaça Tradicional Poços de Caldas entrega um<br/>  sabor aveludado, suave e equilibrado, seu aroma é <br/> refinado, com sutis traços de madeira.</p> 
        <p className="about-text2-cachaca">- Produzida 100% na região <text className="about-text-cachaca">mineira brasileira.</text></p>
        <p className="about-text2-cachaca">- A escolha certa do terreno, uma boa preparação do solo,<br/> uma coleta de cana selecionada, e um processo de alta<br/> qualidade, dão as características exclusivas de nosso<br/> produto.</p>
        <p className="about-text-cachaca">- Aroma refinado e adocicado <text className="about-text2-cachaca">que remete a madeira.</text></p>
        <p className="about-text2-cachaca">- Sabor rico, equilibrado, <text className="about-text-cachaca">suave e aveludado,</text><br/> com notas aromáticas de frutas secas.</p>
        </div> 
        <div className="line-container-cachaca"></div>
        <p className="about-text3-cachaca">750 ml | ALC 40% Vol.</p>  
        <div className="line-container-cachaca"></div>    
        </div>

        </div>
        <div className="content-group3-container-cachaca">
        
        <div className="produto-nutricional-img-container-cachaca">
            <img src={produto} className="produto-nutricional-img-cachaca"/>
        </div>
        
        </div>
      </div>
    </div>
  );
}
