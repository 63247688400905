import React from "react";
import "./BananinhaChocolateMobile.css";
import logo from "../../../assets/bananinha/logo-bananinha.png";
import nutricional from "../../../assets/bananinha/nutricional-bananinha.png"
import produto from "../../../assets/bananinha/produto-bananinha.png"
//@ts-ignore
import Pulse from 'react-reveal/Pulse';
export default function BananinhaMobile() {
  return (
    <div className="container-bananinha-chocolate-mobile">
      <div className="content-group-wrapper-bananinha-chocolate-mobile">
        <div className="content-group-container-bananinha-chocolate-mobile">

          <div className="title-group-container-bananinha-chocolate-mobile">
            <div className="title-container-bananinha-chocolate-mobile">
              <p className="title-bananinha-chocolate-mobile">Bananinha Zero</p>
              <p className="title2-bananinha-chocolate-mobile">com cobertura</p>
            </div>
            <div className="title-container-brown-bananinha-chocolate-mobile">
            <p className="title-brown-bananinha-chocolate-mobile">de chocolate</p>
            </div>
            <div className="title-container2-bananinha-chocolate-mobile">
              <p className="title3-bananinha-chocolate-mobile">
                É fruta de verdade com sabor natural.
              </p>
            </div>
          </div>
          <div className="logo-img-container-bananinha-chocolate-mobile">
            <img src={logo} width="90px" height="67px"></img>
          </div>
        </div>
        <div className="content-group2-container-bananinha-chocolate-mobile">
        <div className="about-bananinha-chocolate-mobile">
        <p className="about-title-bananinha-chocolate-mobile">Nosso produto é:</p>
        <div className="about-text-container-bananinha-chocolate-mobile">  
        <p className="about-text-bananinha-chocolate-mobile">• Sem glúten</p>
        <p className="about-text-bananinha-chocolate-mobile">• Sem adição de açúcar</p>  
        <p className="about-text-bananinha-chocolate-mobile">• Sem adição de adoçantes</p>  
        <p className="about-text-bananinha-chocolate-mobile">• Sem flavorizantes e corantes</p>
        <p className="about-text-bananinha-chocolate-mobile">• Produto vegano</p>
        <p className="about-text-bananinha-chocolate-mobile">• Sem colesterol</p> 
        </div>         
        </div>

        </div>
        <Pulse delay={200}>
        <div className="content-group3-container-bananinha-chocolate-mobile">
      
        
        <div className="produto-nutricional-img-container-bananinha-chocolate-mobile">

            <img src={produto}  className="produto-nutricional-img-bananinha-chocolate-mobile"/>

 
        </div>


        
        </div>
        </Pulse>

      </div>
    </div>
  );
}
