import React from "react";
import {
    BrowserRouter as Router,
    Route
  } from "react-router-dom";
import Home from "./pages/Home/Home"
  export default function RouterMain(){
    return(
        <Router>
          <Route component = {Home}  path="/" exact /> 
        </Router>
    )

  }