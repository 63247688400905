import React from "react";
import "./Vinho2.css"
import leftTop2 from "../../../assets/Vinho2/left-top-vinho2.png"
import rightTop2 from "../../../assets/Vinho2/right-top-vinho2.png"
import rightBottom2 from "../../../assets/Vinho2/right-bottom-vinho2.png"
import logoVinho2 from "../../../assets/Vinho2/logo-vinho2.png"
import logo2Vinho2 from "../../../assets/Vinho2/logo2-vinho2.png"
import produtoVinho2 from "../../../assets/Vinho2/produto-vinho2.png"
//@ts-ignore
import Pulse from 'react-reveal/Pulse';
export default function Vinho2() {
    return(
    <div className="container-vinho2">
        
    <img src={leftTop2}   className="left-top-vinho2"/>  
    <img src={rightTop2}   className="right-top-vinho2"/>  
    <img src={logoVinho2}  className="logo-vinho2"/>  
    <img src={rightBottom2} className="right-bottom-vinho2"/> 
      
    <div className="content-group-vinho2">
    <div className="topbar-vinho2">
    <img src={logo2Vinho2} className="logo2-vinho2"/> 
    <div className="topbar-text-container-vinho2">
    <p className="topbar-text-bold-vinho2">Alto padrão de qualidade <text className="topbar-text-vinho2">para</text></p>
    <p className="topbar-text-vinho2">agradar os paladares mais refinados</p>    
    </div>  
    </div> 
    <div className="content-vinho2"> 
    <div className="content-text-image-vinho2">

    <div className="content-text-group-vinho2">
    <div className="content-text1-container-vinho2">
     <p className="content-text1-vinho2">O Vinho Rose Poços de Caldas foi elaborado mantendo nossa antiga receita entregando um sabor aveludado, suave e equilibrado, seu aroma é refinado com sutis traços de uva.</p> 
     {/* <p className="content-text1-english-vinho2">The Poços de Caldas Wine was elaborated with our classic recipe. The Rosé Wine delivers a velvety, soft and balanced flavour, its aroma is refined with subtle traces of grape.</p>     */}
    </div>    

    <div className="content-text2-container-vinho2">
     <p className="content-text1-vinho2">Produzido 100% em território brasileiro.</p> 
     {/* <p className="content-text1-english-vinho2">100% produced in Brazil</p>     */}
    </div>  

    <div className="content-text2-container-vinho2">
     <p className="content-text1-vinho2">A escolha certa da uva e uma boa preparação do solo, ajudam no desenvolvimento de um produto de altíssima qualidade.</p> 
     {/* <p className="content-text1-english-vinho2">The right choice of the grapes and a good preparation of the land help in the development of a high quality product. </p>     */}
    </div>  

    <div className="content-text2-container-vinho2">
     <p className="content-text1-vinho2">O aroma adocicado e refinado que remete à uva entrega um sabor rico e suave com os melhores vinhedos do Brasil.</p> 
     {/* <p className="content-text1-english-vinho2">Its sweet and refined aroma remits to a tasteful and soft grape from the best Brazilian vineyard. </p>     */}
    </div>  
    </div>
     <img src={produtoVinho2} className="produto-vinho2"/> 

    </div>
    <div className="text-box-container-vinho2">
     <div className="text-box-vinho2">
     <p className="text-box-text-vinho2">Qualidade e sabor</p>    
     <p className="text-box-text2-vinho2">de sempre, agora na</p> 
     <p className="text-box-text-vinho2">praticidade <text className="text-box-text2-junto-vinho2">da</text> lata.</p>   
    </div>  
    {/* <div className="text-box2-vinho2">
     <p className="text-box-text-english-vinho2">Same quality <text className="text-box-text2-english-junto-vinho2">and</text></p>    
     <p className="text-box-text-english-vinho2">flavour <text className="text-box-text2-english-junto-vinho2">as always,</text></p> 
     <p className="text-box-text2-english-vinho2">but now in the</p> 
     <p className="text-box-text-english-vinho2">practice <text className="text-box-text2-english-junto-vinho2">of a</text> can.</p>   
    </div>    */}
    
    </div>
    
    </div>
    </div>     
    </div>    

    );
}   