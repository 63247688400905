import React from 'react';
import logo from './logo.svg';
import './App.css';
import RouterMain from './RouterMain';
import {AudioProvider} from './contexts/Audio';
function App() {
  return (
    <AudioProvider>
    <RouterMain/>
    </AudioProvider>
  );
}

export default App;
