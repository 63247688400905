import React from "react";
import "./CachacaVermelha.css";
import logo from "../../../assets/cachaca-vermelha/logo-cachaca.png";
import produto from "../../../assets/cachaca-vermelha/produto.png";

export default function Cachaca() {
  return (
    <div className="container-cachaca-vermelha">
      <div className="content-group-wrapper-cachaca-vermelha">
        <div className="content-group-container-cachaca-vermelha">

          <div className="title-group-container-cachaca-vermelha">
            <div className="title-container-cachaca-vermelha">
              <p className="title-cachaca-vermelha">Presenteie <br/>Certo!</p>
            </div>
          </div>
          <div className="logo-img-container-cachaca-vermelha">
            <img src={logo} className="logo-img-cachaca-vermelha"></img>
          </div>
        </div>
        <div className="content-group2-container-cachaca-vermelha">
        <div className="about-cachaca-vermelha">
         <div className="about-title-cointainer-cachaca-vermelha"> 
        <p className="about-title-cachaca-vermelha">TRADICIONAL BLEND EXCLUSIVO</p>
        </div>
        <div className="about-text-container-cachaca-vermelha"> 
        <p className="about-text2-cachaca-vermelha">Destilada artesanalmente usando nossa antiga receita,<br/> a Cachaça Tradicional Poços de Caldas entrega um<br/>  sabor aveludado, suave e equilibrado, seu aroma é <br/> refinado, com sutis traços de madeira.</p> 
        <p className="about-text2-cachaca-vermelha">- Produzida 100% na região <text className="about-text-cachaca-vermelha">mineira brasileira.</text></p>
        <p className="about-text2-cachaca-vermelha">- A escolha certa do terreno, uma boa preparação do solo,<br/> uma coleta de cana selecionada, e um processo de alta<br/> qualidade, dão as características exclusivas de nosso<br/> produto.</p>
        <p className="about-text-cachaca-vermelha">- Aroma refinado e adocicado <text className="about-text2-cachaca-vermelha">que remete a madeira.</text></p>
        <p className="about-text2-cachaca-vermelha">- Sabor rico, equilibrado, <text className="about-text-cachaca-vermelha">suave e aveludado,</text><br/> com notas aromáticas de frutas secas.</p>
        </div> 
        <div className="line-container-cachaca-vermelha"></div>
        <p className="about-text3-cachaca-vermelha">750 ml | ALC 40% Vol.</p>  
        <div className="line-container-cachaca-vermelha"></div>    
        </div>

        </div>
        <div className="content-group3-container-cachaca-vermelha">
        
        <div className="produto-nutricional-img-container-cachaca-vermelha">
            <img src={produto} className="produto-nutricional-img-cachaca-vermelha"/>
        </div>
        
        </div>
      </div>
    </div>
  );
}
