import React from "react";
import "./BananinhaChocolate.css";
import logo from "../../../assets/bananinha/logo-bananinha.png";
import produto from "../../../assets/bananinha/produto-bananinha.png"
import nutricional from "../../../assets/bananinha/nutricional-bananinha.png"
import produtoNutricional from "../../../assets/bananinha/produto-nutricional-bananinha.png"
//@ts-ignore
import Pulse from 'react-reveal/Pulse';

export default function BananinhaChocolate() {
  return (
    <div className="container-bananinha-chocolate">
      <div className="content-group-wrapper-bananinha-chocolate">
        <div className="content-group-container-bananinha-chocolate">

          <div className="title-group-container-bananinha-chocolate">
            <div className="title-container-bananinha-chocolate">
              <p className="title-bananinha-chocolate">Bananinha Zero</p>
              <p className="title2-bananinha-chocolate">com cobertura</p>
              
            </div>
            <div className="title-container-brown-bananinha-chocolate">
            <p className="title-brown-bananinha-chocolate">de chocolate</p>
            </div>
            <div className="title-container2-bananinha-chocolate">
              <p className="title3-bananinha-chocolate">
                É fruta de verdade com sabor natural.
              </p>
            </div>
          </div>
          <div className="logo-img-container-bananinha-chocolate">
            <img src={logo} className="logo-img-bananinha-chocolate"></img>
          </div>
        </div>
        <div className="content-group2-container-bananinha-chocolate">
        <div className="about-bananinha-chocolate">
        <p className="about-title-bananinha-chocolate">Nosso produto é:</p>
        <div className="about-text-container-bananinha-chocolate">  
        <p className="about-text-bananinha-chocolate">• Sem glúten</p>
        <p className="about-text-bananinha-chocolate">• Sem adição de açúcar</p>  
        <p className="about-text-bananinha-chocolate">• Sem adição de adoçantes</p>  
        <p className="about-text-bananinha-chocolate">• Sem flavorizantes e corantes</p>
        <p className="about-text-bananinha-chocolate">• Produto vegano</p>
        <p className="about-text-bananinha-chocolate">• Sem colesterol</p> 
        </div>         
        </div>

        </div>
        <Pulse delay={200}>
        <div className="content-group3-container-bananinha-chocolate">
        {/* <div className="produto-img-container-bananinha">
            <img src={produto} width="600px" height="731px" />
        </div>
        <div className="produto-img-nutricional-container-bananinha">
            <img src={nutricional} width="400px" height="262px"/>
        </div> */}
        <div className="produto-nutricional-img-container-bananinha-chocolate">
            <img src={produtoNutricional} className="produto-nutricional-img-bananinha-chocolate"/>
        </div>
        
        </div>
        </Pulse>
      </div>
    </div>
  );
}
