import React, { useEffect, useState } from "react";
import "./Home.css"
import Bananinha from "../../components/web/Bananinha/Bananinha"
import BananinhaChocolate from "../../components/web/BananinhaChocolate/BananinhaChocolate"
import Capa from "../../components/web/Capa/Capa"
import ContraCapa from "../../components/web/ContraCapa/ContraCapa"
import Vinho from "../../components/web/Vinho/Vinho"
import Vinho2 from "../../components/web/Vinho2/Vinho2"
import Cachaca from "../../components/web/Cachaca/Cachaca";
import CachacaVermelha from "../../components/web/CachacaVermelha/CachacaVermelha";
import DoceDeLeite from "../../components/web/DoceDeLeite/DoceDeLeite";
import left from "../../assets/geral/left.png"
import right from "../../assets/geral/right.png"
import Carousel, { arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';


import CapaMobile from "../../components/mobile/CapaMobile/CapaMobile"
import BananinhaMobile from "../../components/mobile/BananinhaMobile/BananinhaMobile";
import BananinhaChocolateMobile from "../../components/mobile/BananinhaChocolateMobile/BananinhaChocolateMobile";
import ContraCapaMobile from "../../components/mobile/ContraCapaMobile/ContraCapaMobile";
import VinhoMobile from "../../components/mobile/VinhoMobile/VinhoMobile";
import Vinho2Mobile from "../../components/mobile/Vinho2Mobile/Vinho2Mobile";
import CachacaMobile from "../../components/mobile/CachacaMobile/CachacaMobile";
import CachacaVermelhaMobile from "../../components/mobile/CachacaVermelhaMobile/CachacaVermelhaMobile";
import DoceDeLeiteMobile from "../../components/mobile/DoceDeLeiteMobile/DoceDeLeiteMobile";


export default function Home() {
  const tamanho = window.innerWidth;
  const [focusHandle, setFocusHandle] = useState(true);
    return(
     <div className="container-home">
      {tamanho > 768 && 
    <Carousel  draggable={false} plugins={[
      {
        resolve: arrowsPlugin,
        options: {
          arrowLeft: <button className="arrow-left-carousel-home"> <img src={left} width="25px" height="25px"/></button>,
          arrowLeftDisabled:<button className="arrow-left-disabled-carousel-home"> <img src={left} width="25px" height="25px"/></button>,
          arrowRight: <button className="arrow-right-carousel-home"><img src={right} width="25px" height="25px"/></button>,
          arrowRightDisabled: <button className="arrow-right-disabled-carousel-home"><img src={right} width="25px" height="25px"/></button>,
          addArrowClickHandler: true,
        }
      }
    ]} >
      

          <Capa/>   
          <Vinho focusHandle={focusHandle} setFocusHandle={setFocusHandle} />
         <Vinho2/>
          <Bananinha/>
         <BananinhaChocolate/>
         <Cachaca/> 
         <CachacaVermelha/>
         <DoceDeLeite/>
         <ContraCapa/>
         </Carousel> 
}

       {tamanho <= 768 && 
      <Carousel
       plugins={['fastSwipe']}
       >

          <CapaMobile/>
          <VinhoMobile/>
          <Vinho2Mobile/>  
          <BananinhaMobile/>
          <BananinhaChocolateMobile/>
          <CachacaMobile/>
          <CachacaVermelhaMobile/>
          <DoceDeLeiteMobile/>
          <ContraCapaMobile/>
         </Carousel> 
}

     </div>   


    );
}