import React from "react";
import "./Bananinha.css";
import logo from "../../../assets/bananinha/logo-bananinha.png";
import produto from "../../../assets/bananinha/produto-bananinha.png"
import nutricional from "../../../assets/bananinha/nutricional-bananinha.png"
import produtoNutricional from "../../../assets/bananinha/produto-nutricional-bananinha.png"
//@ts-ignore
import Pulse from 'react-reveal/Pulse';
export default function Bananinha() {
  return (
    <div className="container-bananinha">
      <div className="content-group-wrapper-bananinha">
        <div className="content-group-container-bananinha">

          <div className="title-group-container-bananinha">
            <div className="title-container-bananinha">
              <p className="title-bananinha">Bananinha Zero</p>
              <p className="title2-bananinha">adição de açúcar</p>
            </div>
            <div className="title-container2-bananinha">
              <p className="title3-bananinha">
                É fruta de verdade com sabor natural.
              </p>
            </div>
          </div>
          <div className="logo-img-container-bananinha">
            <img src={logo} className="logo-img-bananinha"></img>
          </div>
        </div>
        <div className="content-group2-container-bananinha">
        <div className="about-bananinha">
        <p className="about-title-bananinha">Nosso produto é:</p>
        <div className="about-text-container-bananinha">  
        <p className="about-text-bananinha">• Sem glúten</p>
        <p className="about-text-bananinha">• Sem adição de açúcar</p>  
        <p className="about-text-bananinha">• Sem adição de adoçantes</p>  
        <p className="about-text-bananinha">• Sem flavorizantes e corantes</p>
        <p className="about-text-bananinha">• Produto vegano</p>
        <p className="about-text-bananinha">• Sem colesterol</p> 
        </div>         
        </div>

        </div>
        <Pulse delay={200}>
        <div className="content-group3-container-bananinha">
        
        <div className="produto-nutricional-img-container-bananinha">

            <img src={produtoNutricional} className="produto-nutricional-img-bananinha"/>
 
        </div>


        
        </div>
        </Pulse>

      </div>
    </div>
  );
}
