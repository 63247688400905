import {useEffect, useRef} from "react";
import logoCapa from "../../../assets/capa/logo-catalogo.png"
import "./CapaMobile.css"
export default function CapaMobile() {
    const heightWindow =window.innerHeight/3;
    const divRef = useRef(null);
    useEffect(() => {
    //@ts-ignore
    divRef.current.style.height = heightWindow+"px";
    console.log(heightWindow);
    }, [heightWindow]);
    return (
    <div className="container-capa-mobile">
    <div className="logo-capa-container-mobile" >       
    <img src={logoCapa} className="logo-capa-mobile" />
    </div> 
    <div className="footer-capa-mobile" ref={divRef}>
    <h1>Catálogo Digital</h1>    
    </div>    
    </div>    
    

    );
}


