import React from "react";
import logoCapa from "../../../assets/capa/logo-catalogo.png"
import "./Capa.css"
export default function Capa() {
    return (
    <div className="container-capa">    
    <img src={logoCapa}  className="logo-capa"/>
    <div className="footer-capa">
    <h1 className="footer-title-capa">Catálogo Digital</h1>    
    </div>    
    </div>    

    );
}